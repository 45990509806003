import { Component, Input, NgModule } from '@angular/core';
import { RoundButtonModule } from '@app/shared/components/round-button/round-button.component';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { DropDownMenuModule, MenuItem } from '@app/shared/components/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-label-dropdown',
  template: `
    <app-dropdown-menu
      [menuItems]="menuItems"
      (visibleChanged)="visible = $event"
    >
      <div class="dropdown__trigger clickable">
        <ng-container *ngIf="label; else dotsTmpl">
          <span class="dropdown__label">{{ label }}</span>
        </ng-container>
        <ng-template #dotsTmpl>
          <i
            class="center dots-icon-special-size"
            *ngIf="!visible"
            nz-icon
            [nzType]="'icons:more'"
          ></i>
          <i
            class="close-icon center"
            *ngIf="visible"
            nz-icon
            [nzType]="'icons:close'"
          ></i>
        </ng-template>
      </div>
    </app-dropdown-menu>
  `,
  styleUrls: ['label-dropdown.component.less']
})
export class LabelDropdownComponent {
  @Input() public menuItems: MenuItem[];
  @Input() public label: string;

  public visible = false;
}

@NgModule({
  declarations: [LabelDropdownComponent],
  exports: [LabelDropdownComponent],
  imports: [RoundButtonModule, CommonModule, NzIconModule, DropDownMenuModule]
})
export class LabelDropdownMenuModule {}
